export const PrintableData = [

    {
        button: require("../pdfs/printables/A-Z tracing Alphabet Worksheets.pdf"),
        path: `Learn | More`,
        text: `A-Z Tracing`,
        images: require("../images/Kindergarten.jpg")

    },
    {
        button: require("../pdfs/printables/busy binder.pdf"),
        path: `Learn | More`,
        text: `Busy Binder`,
        images: require("../images/busy brind.jpeg")

    },
    {
        button: "https://drive.google.com/file/d/1QI4tz1PSEgUWiSpSEzAI62yKTL9p77aG/view?usp=drivesdk",
        path: `Learn | More`,
        text: `Busy Binder Printouts`,
        images: require("../images/busy binder.png")
    },
    {
        button: "https://drive.google.com/file/d/1QOVm7-9bXCLxTNG9iqABj5juXCc8utT6/view?usp=drivesdk",
        path: `Learn | More`,
        text: `Daily Activity Flash Cards`,
        images: require("../images/daily activity.png")
    },
    {
        button: "https://drive.google.com/file/d/1QP4gialCG-rgwPpUdPwO-5T6ccn35UcO/view?usp=drivesdk",
        path: `Learn | More`,
        text: `Trace the line worksheets`,
        images: require("../images/trac the line.png")
    },
    {
        button: require("../pdfs/printables/ABC Poster.pdf"),
        path: `Learn | More`,
        text: `ABC Poster`,
        images: require("../images/abc-poster-image.jpeg")

    },
    {
        button: require("../pdfs/printables/colorful-number-shapes-colors-shapes-fash-cards.pdf"),
        path: `Learn | More`,
        text: `Colorful Flash Cards`,
        images: require("../images/colorful-number-shapes-colors-shapes-fash-cards.jpeg")

    },
    {
        button: require("../pdfs/printables/Alphabet Flashcards.pdf"),
        path: `Learn | More`,
        text: `a-z Flash Cards`,
        images: require("../images/a-z-flash-cards.jpeg")

    },
    {
        button: require("../pdfs/printables/The planets Flashcards.pdf"),
        path: `Learn | More`,
        text: `Planets Flash Cards`,
        images: require("../images/planet image.jpeg")

    },
    {
        button: "https://drive.google.com/file/d/1F2-PwCynhmosY0bobk7Nvg9pL1VRUizJ/view",
        path: `Learn | More`,
        text: `abc Formation`,
        images: require("../images/abc tracing.jpeg")
    },
    {
        button: "https://drive.google.com/file/d/1NFSBV5BWXCA4bsuMJE5BbYcIldprYLtH/view",
        path: `Learn | More`,
        text: `Number Formation`,
        images: require("../images/number tracing.jpeg")
    },
    {
        button: "https://drive.google.com/file/d/19aLP56DOMVLNJcLdyhAaf9HJOodtZzun/view?usp=drivesdk",
        path: `Learn | More`,
        text: `Urdu Formation`,
        images: require("../images/urdu tracing.jpeg")
    },
    {
        button: "https://drive.google.com/file/d/1QQwWm9-kXClvXc6E6iO1yD6BjDcMXXWJ/view?usp=drivesdk",
        path: `Learn | More`,
        text: `Weekly Planner`,
        images: require("../images/weekly planner.png")
    },
]