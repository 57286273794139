import React from 'react';

const ComingSoon = () => {
    const pageStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
        fontSize: '34px'

    };

    return (
        <div style={pageStyle}>

            <p>Coming Soon...</p>
        </div>
    );
}

export default ComingSoon;
